<template>
    <div class="page-managechannel">
        <b-row class="m-0 mb-3">
            <b-col class="p-0 bg-white text-purple rounded me-3">
                <b-row class="m-0 p-3">
                    <b-col class="p-0 d-flex align-items-center flex-basis-content" cols="2">
                        <b-icon class="bg-green rounded-circle text-white p-3" icon="headset" font-scale="4"></b-icon>
                    </b-col>
                    <b-col class="p-0 px-3 d-flex flex-column justify-content-center">
                        <div>
                            Operadores
                        </div>
                        <div class="fs-4 fw-bold lh-085">
                            {{ operatorsCount.online }}
                        </div>
                        <div>
                            Online
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="p-0 bg-white text-purple rounded me-3">
                <b-row class="m-0 p-3">
                    <b-col class="p-0 d-flex align-items-center flex-basis-content" cols="2">
                        <b-icon class="bg-danger rounded-circle text-white p-3" icon="headset" font-scale="4"></b-icon>
                    </b-col>
                    <b-col class="p-0 px-3 d-flex flex-column justify-content-center">
                        <div>
                            Operadores
                        </div>
                        <div class="fs-4 fw-bold lh-085">
                            {{ operatorsCount.offline }}
                        </div>
                        <div>
                            Offline
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="p-0 bg-white text-purple rounded">
                <b-row class="m-0 p-3">
                    <b-col class="p-0 d-flex align-items-center flex-basis-content" cols="2">
                        <b-icon class="bg-green rounded-circle text-white p-3" icon="people" font-scale="4"></b-icon>
                    </b-col>
                    <b-col class="p-0 px-3 d-flex flex-column justify-content-center">
                        <div>
                            Contatos
                        </div>
                        <div class="fs-4 fw-bold lh-085">
                            {{ contacts.total }}
                        </div>
                        <div>
                            Cadastrados
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <!-- <b-card class="cardcreatechannel mb-3">
            <h5 class="cardcreatechanneltitle">Deseja criar um novo canal? </h5>
            <b-button class="cardcreatechannelbtn" v-b-modal.modal-pickChannelType>Clique Aqui</b-button>
        </b-card> -->
        <b-card class="channelcard m-0" body-class="p-0">
            <div class="d-flex justify-content-between p-4 m-0 border-bottom">
                <h5 class="channelcardtitle">Canais</h5>
                <div class="channelcardheaderright">
                    <span class="channelcardtexttotal">Total de Canais</span>
                    <span class="channelcardnumbertotal">{{ channels.total }}</span>
                    <b-button class="border-0 px-3 text-white me-3" variant="red" @click="disableFilter()" v-if="filterEnabled">Remover Filtro</b-button>
                    <b-button class="border-0 px-3 bg-green me-3" v-b-modal.modal-channelfilter>Filtrar</b-button>
                    <b-button class="border-0 px-3 bg-green" v-b-modal.modal-pickChannelType v-if="!user.admin?.hideCreateChannelButton">Criar</b-button>
                </div>
            </div>
            <div v-if="channels.length">
                <div class="px-4 py-3 m-0 border-bottom bg-grey">
                    <b-row class="m-0 p-0">
                        <b-col class="p-0 d-flex align-items-center" cols="3">
                            <div class="channelcardshtitle" role="button" @click="sortChannels('name')">Canal</div> 
                        </b-col>
                        <!-- <b-col class="p-0">
                            <div class="channelcardshtitle">Ativo</div>
                        </b-col> -->
                        <b-col class="p-0 d-flex align-items-center justify-content-start" cols="1">
                            <div class="channelcardshtitle" role="button" @click="sortChannels('status')">Status</div>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center justify-content-start">
                            <div class="channelcardshtitle" role="button" @click="sortChannels('type')">Tipo</div>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center justify-content-start">
                            <div class="channelcardshtitle" role="button" @click="sortChannels('lastSended')">Último envio</div>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center justify-content-start">
                            <div class="channelcardshtitle" role="button" @click="sortChannels('lastReceived')">Último recebimento</div>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center">
                            <!-- <div class="channelcardshboxes justify-content-end">
                                <label><input type="checkbox" name="checkbox-01" /><span></span></label>
                                <b-form-select class="channelcardshselect"></b-form-select>
                            </div> -->
                        </b-col>
                    </b-row>
                </div>
                <div class="channelcardbody">
                    <b-row class="px-4 py-3 m-0 border-bottom" v-for="item in channels" :key="item.id">
                        <b-col class="p-0 d-flex align-items-center" cols="3">
                            <div class="d-flex align-items-center w-100">
                                <!-- <b-form-checkbox class="channelcardnamecb" style="display: inline"></b-form-checkbox> -->
                                <span class="d-flex align-items-center w-100 overflow-hidden gap-2">
                                    <b-avatar class="bg-secondary text-light" size="3.5em" :src="item.avatar"></b-avatar>
                                    <span class="d-inline-flex flex-column text-purple lh-sm w-100">
                                        <div class="fw-bold text-truncate">{{ item.name }}</div>
                                        <div>{{ item.channelNumber }}</div>
                                        <div class="text-secondary smaller-text" @click="copyToClipboard(item._id)" role="button">
                                        #{{ item._id }}
                                    </div>
                                        <div v-if="item.mainChannelId" class="badge bg-purple rounded-pill w-fit">Canal Secundário</div>
                                    </span>
                                </span>
                            </div>
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center justify-content-start" cols="1">
                            <div>
                                <!-- <span class="form-check form-switch">
                                    <b-form-checkbox v-model="item.active" :checked="item.active"  name="check-button"></b-form-checkbox>
                                </span> -->
                            </div>
                            <div class="text-purple">
                                <span class="d-inline-flex align-items-center" v-if="item.blocked">
                                    Bloqueado
                                    <b-icon class="ms-1" variant="danger" icon="circle-fill" font-scale=".5"></b-icon>
                                </span>
                                <span class="d-inline-flex align-items-center" v-else-if="item.status == 'authenticated'">
                                    Online
                                    <b-icon class="ms-1" variant="green" icon="circle-fill" font-scale=".5"></b-icon>
                                </span>
                                <span class="d-inline-flex align-items-center" v-else>
                                    Offline
                                    <b-icon class="ms-1" variant="danger" icon="circle-fill" font-scale=".5"></b-icon>
                                </span>
                            </div>
                        </b-col>  
                        <b-col class="p-0 d-flex align-items-center text-capitalize text-purple">
                            {{ item.apiType }}
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center text-capitalize text-purple">
                            {{ item.channelConfig?.lastSendedMessage | date('MMM DD [●] HH:mm:ss') }}
                        </b-col>
                        <b-col class="p-0 d-flex align-items-center text-capitalize text-purple">
                            {{ item.channelConfig?.lastReceivedMessage | date('MMM DD [●] HH:mm:ss') }}
                        </b-col>
                        <b-col class="p-0 hstack flex-wrap gap-1 justify-content-end">   
                            <b-button class="channelcardoptionsbtn" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" style="background-color:hsl(94deg 44% 65%)" @click="selectChannel(item)" v-b-modal.modal-channelSave>
                                <b-icon icon="pencil-square"></b-icon>
                            </b-button>
                            <b-button class="channelcardoptionsbtn bg-purple" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-purple top-0', boundary: 'document' }" title="Importar Contatos" @click="selectChannel(item)" v-b-modal.modal-importContacts>
                                <b-icon icon="file-earmark-spreadsheet"></b-icon>
                            </b-button>
                            <b-button class="channelcardoptionsbtn" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Ver painel do cliente" @click="openClientPanel(item)">
                                <b-icon icon="eye"></b-icon>
                            </b-button>
                            <!-- <b-button class="channelcardoptionsbtn" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove' }" title="Remover" style="background-color:hsl(360deg 83% 69%)" @click.prevent="selectChannel(item)" v-b-modal.modal-channeldisable>
                                <b-icon icon="trash"></b-icon>
                            </b-button> -->
                            <b-button class="channelcardoptionsbtn" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove top-0', boundary: 'document' }" title="Remover" style="background-color:hsl(360deg 83% 69%)" @click.prevent="selectChannel(item)" v-b-modal.modal-channeldisable>
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                            <b-button class="channelcardoptionsbtn bg-warning" v-b-tooltip.hover.bottom="{ variant: 'warning', customClass: 'top-0', boundary: 'document' }" title="Desconectar" @click="selectChannel(item)" v-b-modal.modal-channelDisconnect>
                                <b-icon icon="power"></b-icon>
                            </b-button>
                            <b-button class="channelcardoptionsbtn bg-primary" v-b-tooltip.hover.bottomright="{ variant: 'primary', customClass: 'top-0 me-2', boundary: 'document' }" title="Configurar Contêiner" @click="selectChannel(item)" v-b-modal.modal-channelContainerConfig v-if="(item.apiType === 'business' || item.apiType === 'api0') && serverName">
                                <b-icon icon="gear"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
                <div class="d-flex justify-content-between align-items-center border-top px-4 py-3" v-if="channels.total > 10">
                    <b-pagination class="p-0 m-0" v-model="currentPage" :total-rows="channels.total" :per-page="channelsPerPage" @change="pageChange" />
                    <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * channelsPerPage) - channelsPerPage + 1 : currentPage }} - {{(currentPage * channelsPerPage > channels.total) ? channels.total : currentPage * channelsPerPage}} de {{channels.total}} canais cadastrados</div>
                </div>
            </div>
            <div class="text-center text-secondary p-3 bg-white" v-else>
                Nenhum canal encontrado!
            </div>
        </b-card>
        <b-modal id="modal-channelfilter" ref="modal-channelfilter" title="Filtrar Canais" header-class="py-0" hide-footer>
            <b-form-group label="Servidor">
                <b-form-select class="w-100 py-1" style="border-color: #ccc; border-radius: 0.3em" v-model="filters.server">
                    <b-form-select-option :value="server.code" v-for="server in servers" :key="server.id">
                        {{ server.name }}
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-form-group class="mt-3" label="Palavra-Chave">
                <b-form-input class="py-1" v-model="filters.search" @keydown.enter.exact.prevent="loadChannels()"></b-form-input>
            </b-form-group>
            <div class="pt-3">
                <b-button class="btns btnconfirm" @click="loadChannels()">Filtrar</b-button>
                <b-button class="btns btncancel" @click="$bvModal.hide('modal-channelfilter')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-pickChannelType" ref="modal-pickChannelType" title="Tipo de Canal" header-class="py-0" hide-footer>
             <b-form-group class="text-secondary" label="">
                <b-form-radio v-model="channelType" value="1"> <div class="ms-1">Primário</div> </b-form-radio>
                <b-form-radio v-model="channelType" value="2"> <div class="ms-1">Secundário</div> </b-form-radio>
            </b-form-group>
            <div class="pt-3">
                <b-button class="btns btnconfirm" @click="pickTypeNext()">Avançar</b-button>
                <b-button class="btns btncancel" @click="$bvModal.hide('modal-pickChannelType'); channelType = null">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-pickChannelServer" ref="modal-pickChannelServer" title="Servidor do Canal" header-class="py-0" hide-footer>
             <b-form-group class="text-secondary" label="">
                <b-form-radio value="1">Primário</b-form-radio>
                <b-form-radio value="2">Secundário</b-form-radio>
            </b-form-group>
            <div class="pt-3">
                <b-button class="btns btnconfirm" @click="$bvModal.hide('modal-pickChannelServer');">Avançar</b-button>
                <b-button class="btns btncancel" @click="$bvModal.hide('modal-pickChannelServer');">Voltar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-pickPrimaryChannel" ref="modal-pickPrimaryChannel" title="Selecionar Canal Primário" header-class="py-0" hide-footer>
            <b-form-input type="text" placeholder="Buscar por Nome ou Número" v-model="primaryChannelSearch"></b-form-input>
            <div class="mt-2" v-for="item in primaryOnly(channels)" :key="item.id">
                <div class="text-purple fw-bold p-2 darkenOnHover rounded" role="button" @click="setMainChannelData(item._id)">
                    <span>
                        <b-avatar class="bg-secondary text-light" size="3em" :src="item.avatar"></b-avatar>
                        {{ item.name }}
                    </span>
                </div> 
            </div>
            <div class="pt-3">
                <b-button class="btns btncancel" @click="$bvModal.hide('modal-pickPrimaryChannel'); objAux.mainChannelId = null">Voltar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-channelSave" ref="modal-channelSave" :title="objAux._id ? 'Editar Dados' : 'Criar Canal'" v-if="objAux">
            <form @submit.prevent="saveChannel">
                <div class="modal-channelSaveheader">
                    <b-form-file
                        class="inputavatar"
                        @input="setChannelAvatar"
                    >
                        <template #placeholder>
                            <span class="modal-channeliconspan">
                                <b-avatar class="bg-secondary text-light channelicon modal-channelicon" :src="objAux.avatar"></b-avatar>
                                <span class="modal-editImgSpan">
                                    <div class="modal-channelicon rounded-circle modal-channeliconshadow"></div>
                                    <div class="modal-channelshadowtext">
                                        <b-icon class="modal-channelshadowicon" icon="image"></b-icon>
                                        Alterar
                                    </div>
                                </span>
                            </span>
                        </template>
                        <template #file-name>
                            <span class="modal-channeliconspan">
                                <b-avatar class="bg-secondary text-light channelicon modal-channelicon" :src="objAux.avatar"></b-avatar>
                                <span class="modal-editImgSpan">
                                    <div class="modal-channelicon rounded-circle modal-channeliconshadow"></div>
                                    <div class="modal-channelshadowtext">
                                        <b-icon class="modal-channelshadowicon" icon="image"></b-icon>
                                        Alterar
                                    </div>
                                </span>
                            </span>
                        </template>
                    </b-form-file>
                    <b-form-input class="w-50 bg-transparent text-white border-0 border-bottom border-radius-0 rounded-0 text-center shadow-none" placeholder="Nome do Canal" v-model="objAux.name" required></b-form-input>
                </div>
                <div class="modal-channelSavecontent px-4">
                    <div class="inputdiv text-purple">
                        <div v-if="!objAux.mainChannelId">
                            <div class="d-flex justify-content-between border-bottom pt-4 pb-2">
                                <h5>Dados do Canal</h5>
                            </div>
                            <b-row class="pt-3">
                                <b-col>
                                    <b-form-group class="m-0" label="Email">
                                        <b-form-input type="email" v-model="objAux.login" autocomplete="nope" :required="!objAux._id"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group class="m-0" label="Senha">
                                        <b-form-input type="password" v-model="objAux.password" autocomplete="nope" :required="!objAux._id"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <div>
                            <div class="d-flex justify-content-between border-bottom pt-4 pb-2">
                                <h5>Configurações do Canal</h5>
                                <div class="bg-purple rounded-pill px-2 text-white" v-if="objAux.mainChannelId">Canal Primário - {{ objAux.mainChannelName || 'loading' }}</div>
                            </div>
                            <b-row class="d-flex align-items-start pt-3">
                                <b-col>
                                    <b-form-group label="País">
                                        <v-select class="countrySelect border-grey" v-model="objAux.ddi" :options="countryOptions" @input="refreshObjAux">
                                            <template slot="option" slot-scope="option">
                                                <!-- <span :class="`flag-icon flag-icon-${option.value.toLowerCase()} flag-icon-squared me-2`"></span> -->
                                                <span>{{ option.label }}</span>
                                            </template>
                                            <template #selected-option="value">
                                                {{ setSelectedOption(countryOptions, value) }}
                                            </template>
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Número do WhatsApp">
                                        <b-form-input @input="checkNumberAndDDI" v-maska="objAux.ddi ? (objAux.ddi == '55' ? [`55(##)####-####`,`55(##)#####-####`] : `${objAux.ddi?.value || objAux.ddi}#*`) : '#*'" v-model="objAux.channelNumber" autocomplete="nope" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <!-- <b-col>
                                    <b-form-group label="Número do WhatsApp">
                                        <b-form-input type="tel" v-model="objAux.channelNumber" required></b-form-input>
                                    </b-form-group>
                                </b-col> -->
                                <!-- <b-col>
                                    <b-form-group label-class="p-0" style="font-size: 0.85em;" label="Intervalo entre Comunicação Ativa">
                                        <b-form-input type="number" style="margin-top: 1.9em" v-model="objAux.attendanceMessageThreshold"></b-form-input>
                                    </b-form-group>
                                </b-col> -->
                            </b-row>
                            <!-- <b-row class="d-flex align-items-end" v-if="!objAux.mainChannelId">
                                <b-col>
                                    <b-form-group label="Horário Inicial de Envio">
                                        <b-form-input type="time" v-model="objAux.sendStart" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Horário Final de Envio">
                                        <b-form-input type="time" v-model="objAux.sendFinish" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row> -->
                            <b-row class="d-flex align-items-end" v-if="!objAux.mainChannelId && !user.admin?.hideOperatorsAndChannelQuantityFields">
                                <b-col>
                                    <b-form-group label="Quantidade de usuários operadores">
                                        <b-form-input type="number" v-model="objAux.channelConfig.contractedPlan.numberOperators"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Quantidade de canais">
                                        <b-form-input type="number" v-model="objAux.channelConfig.contractedPlan.numberChannels"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end">
                                <b-col v-if="!user.admin?.hideApiTypeField">
                                    <b-form-group label="Tipo">
                                        <b-form-select class="w-100 px-2" style="border-color: #dedede; border-radius: 0.3em; padding: 0.4em 0" v-model="objAux.apiType" required>
                                            <b-form-select-option value="business">
                                                WPP Business
                                            </b-form-select-option>
                                            <b-form-select-option value="cloud">
                                                WPP Cloud
                                            </b-form-select-option>
                                            <b-form-select-option value="gupshup" v-if="user.login == 'admin' || user.admin?.master">
                                                WPP Enterprise
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col v-if="objAux.apiType === 'business' && (user.admin?.master || user.admin?._id === '67cc430d6c47a0c733ee1ced')">
                                    <b-form-group label="API"> <!-- se for Business -->
                                        <b-form-select class="w-100 px-2" style="border-color: #dedede; border-radius: 0.3em; padding: 0.4em 0" v-model="objAux.server" required>
                                            <b-form-select-option :value="server.code" v-for="server in servers.filter(el => ['business','api0'].includes(el.apiType) && (el.code?.toString().length <= 3 || el.code === objAux._id))" :key="server.id">
                                                {{ server.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end">
                                <b-col v-if="!user.admin?.hideApiTypeField && objAux.apiType == 'business'">
                                    <b-form-group label="Habilitação">
                                        <b-form-radio-group class="hstack gap-2" v-model="objAux.channelConfig.enableQRThroughChannel" @change="updateObjAux">
                                            <b-form-radio :value="true"> <div class="ms-1">QR</div> </b-form-radio>
                                            <b-form-radio :value="false"> <div class="ms-1">Instância</div> </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'cloud' || objAux.apiType == 'gupshup'">
                                <b-col>
                                    <b-form-group :label="`Token ${objAux.apiType}`">
                                        <b-form-input v-model="objAux.apiKey"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'gupshup'">
                                <b-col>
                                    <b-form-group label="App">
                                        <b-form-input v-model="objAux.app"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'gupshup'">
                                <b-col>
                                    <b-form-group label="AppName">
                                        <b-form-input v-model="objAux.appName"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'gupshup'">
                                <b-col>
                                    <b-form-group label="AppId">
                                        <b-form-input v-model="objAux.gupshupAppId"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="mb-3" v-if="objAux.apiType == 'gupshup'">
                                <div>
                                    Valores
                                </div>
                                <div class="vstack gap-3 border rounded p-3">
                                    <b-row class="d-flex align-items-end mb-3" v-if="objAux.apiType == 'gupshup'">
                                        <b-col>
                                            <b-form-group label="HSM Marketing">
                                                <b-form-input type="number" step="0.0001" v-model="objAux.channelConfig.gupshup.marketingCost"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'gupshup'">
                                        <b-col>
                                            <b-form-group label="HSM Utilitário">
                                                <b-form-input type="number" step="0.0001" v-model="objAux.channelConfig.gupshup.utilityCost"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'gupshup'">
                                        <b-col>
                                            <b-form-group label="HSM Autenticação">
                                                <b-form-input type="number" step="0.0001" v-model="objAux.channelConfig.gupshup.authenticationCost"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'gupshup'">
                                        <b-col>
                                            <b-form-group label="Mensagem de Serviço">
                                                <b-form-input type="number" step="0.0001" v-model="objAux.channelConfig.gupshup.serviceCost"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'gupshup'">
                                        <b-col>
                                            <b-form-group label="Mensagem de Sessão">
                                                <b-form-input type="number" step="0.0001" v-model="objAux.channelConfig.gupshup.sessionCost"></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'cloud'">
                                <b-col>
                                    <b-form-group label="Identificação do número de telefone">
                                        <b-form-input v-model="objAux.phoneNumberId"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'cloud'">
                                <b-col>
                                    <b-form-group label="Identificação da conta do WhatsApp Business">
                                        <b-form-input v-model="objAux.idWhatsappBusiness"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'cloud'">
                                <b-col>
                                    <b-form-group label="AppId">
                                        <b-form-input v-model="objAux.cloudAppId"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'cloud'">
                                <b-col>
                                    <b-form-group label="Token Gotalk">
                                        <div class="position-relative" @click="copyToClipboard('EAAO0Rz5rDh4BAFSPtc6aJlFCZBifXzUIoH95tyIwJOnZA0Xt94MiBN8TeLeg4TcAhzt9VTK5UEBPcnqZCsPzDRb7YHe8veZAOORDol6oseueKIlQXGmKBBZB1W4ETFlhSr1YQr7sCwnT8KEk54vcJ4YIqEQJmKyFekLFcntvwfzAwZButH8rCBVAiCI8ZBlxJbFbHl9n5UurQZDZD'); showMessage('Copiado com sucesso!')">
                                            <b-form-input class="pe-4" placeholder="EAAO0Rz5rDh4BAFSPtc6aJlFCZBifXzUIoH95tyIwJOnZA0Xt94MiBN8TeLeg4TcAhzt9VTK5UEBPcnqZCsPzDRb7YHe8veZAOORDol6oseueKIlQXGmKBBZB1W4ETFlhSr1YQr7sCwnT8KEk54vcJ4YIqEQJmKyFekLFcntvwfzAwZButH8rCBVAiCI8ZBlxJbFbHl9n5UurQZDZD" disabled></b-form-input>
                                            <b-icon class="position-absolute darkenTextOnHover" style="right: .25em; bottom: 28%" icon="files" flip-v flip-h></b-icon>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="objAux.apiType == 'cloud'">
                                <b-col>
                                    <b-form-group label="Webhook Cloud">
                                        <div class="position-relative" @click="copyToClipboard(`https://${windowHost}/webhooks/facebook`); showMessage('Copiado com sucesso!')">
                                            <b-form-input class="pe-4" :placeholder="`https://${windowHost}/webhooks/facebook`" disabled></b-form-input>
                                            <b-icon class="position-absolute darkenTextOnHover" style="right: .25em; bottom: 28%" icon="files" flip-v flip-h></b-icon>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end">
                                <b-col v-if="!objAux.channelConfig.enableQRThroughChannel && !user.admin?.hideInstanceField && objAux.apiType === 'business'">
                                    <b-form-group label="Instância">
                                        <b-form-input type="text" v-model="objAux.instance"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Data de Cadastramento">
                                        <b-form-input type="date" v-model="objAux.createdAt"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end">
                                <b-col>
                                    <b-form-group label="Servidor">
                                        <b-form-select class="w-100 px-2" style="border-color: #dedede; border-radius: 0.3em; padding: 0.4em 0" required v-model="objAux.serverSocket">
                                            <b-form-select-option class="text-capitalize" :value="server._id" v-for="server in serversConfig" :key="server._id">
                                                {{ server.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end" v-if="!objAux.mainChannelId">
                                <b-col>
                                    <b-form-group label="URL do Webhook">
                                        <b-form-input type="text" v-model="objAux.webhookUrl"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row v-if="objAux.channelConfig">
                                <b-form-checkbox class="d-flex" v-model="objAux.channelConfig.operationalChannelEnabled" @change="refreshObjAux" :disabled="objAux.primaryChannelApiType == 'cloud' && objAux.channelConfig.operationalChannelEnabled && objAux.apiType != 'cloud'">
                                    <div class="ms-2">
                                        Ativar Recurso de Canal Operacional
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox class="d-flex" v-model="objAux.fromMeEnabled" @change="refreshObjAux">
                                    <div class="ms-2">
                                        Verificar mensagens "fromMe"
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox class="d-flex" v-model="objAux.disableActiveCommunication" @change="refreshObjAux" v-if="objAux.mainChannelId && (objAux.apiType === 'gupshup' || objAux.apiType === 'cloud')">
                                    <div class="ms-2">
                                        Desativar Comunicação Ativa
                                    </div>
                                </b-form-checkbox>
                                <b-form-checkbox class="d-flex" v-model="objAux.channelConfig.asteriskFinishAtAnyStatus" @change="refreshObjAux" v-if="!objAux.mainChannelId">
                                    <div class="ms-2">
                                        Ativar "*" finalizar atendimento em qualquer status
                                    </div>
                                </b-form-checkbox>
                                <div v-if="objAux.channelConfig.operationalChannel">
                                    <b-form-group class="pt-3" label="Direcionar para:">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="objAux.channelConfig.operationalChannel.action" @change="refreshObjAux">
                                            <b-form-select-option value="redirectDepartment" v-if="objAux.mainChannelId ? !objAux.channelConfig.modules.simultaneousAttendanceAmongDifferentChannels : true">Departamento</b-form-select-option>
                                            <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="mt-2" :label="`Selecione o ${ objAux.channelConfig.operationalChannel.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="objAux.channelConfig.operationalChannel.action">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="objAux.channelConfig.operationalChannel.id" v-if="objAux.channelConfig.operationalChannel.action == 'redirectDepartment'">
                                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="objAux.channelConfig.operationalChannel.id" v-else-if="objAux.channelConfig.operationalChannel.action == 'redirectOperator'">
                                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </b-row>
                            <!-- <b-row class="mb-2">
                                <b-form-checkbox v-model="objAux.channelConfig.instantAttendance">
                                    <div class="ms-2">Painel do Operador (atendimento aberto instantâneamente)</div>
                                </b-form-checkbox>
                            </b-row> -->
                        </div>
                        <div v-if="!objAux.mainChannelId">
                            <div class="d-flex justify-content-between border-bottom pb-2 mb-3">
                                <h5>Módulos</h5>
                            </div>
                            <!-- <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.administrative">
                                    <div class="ms-2">
                                        Administrativo
                                    </div>
                                </b-form-checkbox>
                            </div> -->
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.api">
                                    <div class="ms-2">
                                        API
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.enableGroup" v-if="objAux.apiType == 'business'">
                                    <div class="ms-2">
                                        Grupos
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.quickAnswers">
                                    <div class="ms-2">
                                        Respostas Rápidas
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.sharedQueue">
                                    <div class="ms-2">
                                        Fila Compartilhada
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.satisfactionSurvey">
                                    <div class="ms-2">
                                        Pesquisa de Satisfação
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.activeCommunication" @change="refreshObjAux()">
                                    <div class="ms-2">
                                        Intervalo de Comunicação Ativa
                                    </div>
                                </b-form-checkbox>
                                <b-form-group class="mb-3" label="Intervalo entre Comunicação Ativa:" label-class="fw-bold" v-if="objAux.channelConfig.modules.activeCommunication">
                                    <b-input-group>
                                        <b-form-input class="border-grey" v-model="objAux.channelConfig.activeCommunicationInterval" type="number"></b-form-input>
                                        <template #append>
                                            <div class="bg-grey h-100 d-flex align-items-center rounded-end px-2 border">
                                                Segundos
                                            </div>
                                        </template>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                            <div v-if="objAux.apiType == 'gupshup'">
                                <b-form-checkbox v-model="objAux.channelConfig.modules.products" @change="refreshObjAux">
                                    <div class="ms-2">
                                        Produtos
                                    </div>
                                </b-form-checkbox>
                                <div class="ms-4" v-if="objAux.channelConfig.modules.products">
                                    <b-form-checkbox class="d-flex" v-model="objAux.channelConfig.modules.productsFromIntegration">
                                    <div class="ms-2">
                                        Produtos obtidos via integração
                                    </div>
                                </b-form-checkbox>
                                </div>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.linearChatbot">
                                    <div class="ms-2">
                                        Chatbot Linear
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.simultaneousAttendanceAmongDifferentChannels">
                                    <div class="ms-2">
                                        Atendimento Simultâneo
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.operatorAttendanceTimer">
                                    <div class="ms-2">
                                        Apresentar Tempo de Atendimento
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.crm">
                                    <div class="ms-2">
                                        CRM
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.logoffRequiresAttendancesToFinish">
                                    <div class="ms-2">
                                        Desativar logoff irrestrito
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox class="d-flex gap-2" v-model="objAux.channelConfig.modules.schedule">
                                    Agendamento
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox class="d-flex gap-2" v-model="objAux.channelConfig.modules.broadcastList" v-if="objAux.apiType === 'gupshup' || objAux.apiType === 'cloud'">
                                    Campanha
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox class="d-flex gap-2" v-model="objAux.channelConfig.modules.sequentialDistribution" @change="refreshObjAux">
                                    Distribuição de fila sequencial
                                </b-form-checkbox>
                                <b-form-checkbox class="d-flex gap-2 ms-4" v-model="objAux.channelConfig.modules.sequentialDistributionOffline" v-if="objAux.channelConfig.modules.sequentialDistribution">
                                    Distribuição de fila de espera sequencial
                                </b-form-checkbox>
                            </div>
                            <div>
                                <b-form-checkbox v-model="objAux.channelConfig.modules.internalChat">
                                    <div class="ms-2">
                                        Chat Interno
                                    </div>
                                </b-form-checkbox>
                            </div>
                            <b-form-checkbox class="d-flex gap-2" v-model="objAux.channelConfig.modules.importContacts">
                                Importar contatos
                            </b-form-checkbox>
                            <b-form-checkbox class="d-flex gap-2" v-model="objAux.channelConfig.modules.ai" @change="onChangeModuleAI">
                                IA Gotalk
                            </b-form-checkbox>
                            <div class="ms-4" v-if="objAux.channelConfig.modules.ai">
                                <b-form-group label-class="fw-bold" label="Prompt:">
                                    <b-form-textarea
                                        class="px-2"
                                        placeholder="Prompt"
                                        rows="3"
                                        no-resize
                                        v-model="objAux.aiSystemPrompt"
                                    />
                                </b-form-group>
                            </div>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-content-between border-bottom pb-2 mb-3">
                                <h5>Módulos</h5>
                            </div>
                            <b-form-checkbox class="d-flex gap-2" v-model="objAux.channelConfig.modules.broadcastList" v-if="objAux.apiType === 'gupshup' || objAux.apiType === 'cloud'">
                                Campanha
                            </b-form-checkbox>
                        </div>
                        <div v-if="!objAux.mainChannelId">
                            <div class="d-flex justify-content-between border-bottom pb-2 my-3">
                                <h5>Perfil Comercial</h5>
                            </div>
                            <b-row class="d-flex align-items-end">
                                <b-col>
                                    <b-form-group label="Razão Social">
                                        <b-form-input type="text" v-model="objAux.companyName"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="CNPJ ou CPF">
                                        <b-form-input type="text" v-maska='["###.###.###-##", "##.###.###/####-##"]' v-model="objAux.companyCnpj"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end">
                                <b-col>
                                    <b-form-group label="Email">
                                        <b-form-input type="text" v-model="objAux.companyEmail"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Site">
                                        <b-form-input type="text" v-model="objAux.companyWebsite"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end">
                                <b-col>
                                    <b-form-group label="CEP">
                                        <b-form-input type="text" v-maska="'#####-###'" @input="setAddressByCep" v-model="objAux.zipcode"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Endereço">
                                        <b-form-input type="text" v-model="objAux.street"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="d-flex align-items-end">
                                <b-col>
                                    <b-form-group label="Complemento">
                                        <b-form-input type="text" v-model="objAux.complement"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div v-if="!objAux.mainChannelId">
                        <b-form-checkbox v-model="objAux.blocked">
                            <div class="ms-2 text-secondary mb-2">
                                Bloquear Canal
                            </div>
                        </b-form-checkbox>
                        <b-form-checkbox v-model="objAux.importGobot">
                            <div class="ms-2 text-secondary">
                                Importar Gobot
                            </div>
                        </b-form-checkbox>
                        <div class="mt-2" v-if="objAux.importGobot">
                            <form @submit.prevent="importGobot">
                                <b-row class="d-flex align-items-end text-purple">
                                    <b-col>
                                        <b-form-group label="ClientId">
                                            <b-form-input type="text" v-model="objAux.gobotClientId"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Password">
                                            <b-form-input type="text" v-model="objAux.gobotPassword"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="d-flex align-items-end text-purple mt-2">
                                    <b-col>
                                        <b-form-group label="Pages">
                                            <b-form-input type="number" min="0" placeholder="1" v-model="objAux.gobotPages"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </form>
                            <!-- <div class="bg-grey border border-dark rounded p-2" v-text="importGobotSuccess || 'Foram importados:\n${data.closedProtocols || 0} protocolos,\n${data.gotalkDepartments || 0} departamentos,\n${data.gotalkOperators || 0} operadores\ne um total de ${data.mgsLength || data.msgsLength || 0} mensagens'"></div> -->
                            <div class="bg-grey border border-dark rounded p-2" v-if="importGobotSuccess">
                                <div v-if="!importGobotSuccess?.mgsLength || !importGobotSuccess?.msgsLength">
                                    Dados já sincronizados!
                                </div>
                                <div v-else>
                                    <div>
                                        Foram importados:
                                    </div>
                                    <div class="ms-3">
                                        {{ importGobotSuccess?.gotalkDepartments || 0  }} departamentos,
                                    </div>
                                    <div class="ms-3">
                                        {{ importGobotSuccess?.gotalkOperators || 0  }} operadores e
                                    </div>
                                    <div class="ms-3">
                                        um total de {{ importGobotSuccess?.mgsLength || importGobotSuccess?.msgsLength || 0 }} mensagens.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-form-checkbox v-model="objAux.importServer" v-if="objAux._id">
                            <div class="ms-2 mt-2 text-secondary">
                                Importar/Exportar servidor
                            </div>
                        </b-form-checkbox>
                        <div class="mt-2" v-if="objAux.importServer">
                            <form @submit.prevent="importServer">
                                <b-row class="d-flex align-items-end text-purple">
                                    <b-col>
                                        <b-form-group label="Origem">
                                            <b-form-select class="w-100 py-1" style="border-color: #ccc; border-radius: 0.3em" v-model="objAux.importFrom">
                                                <b-form-select-option :value="server._id" v-for="server in serversConfig" :key="server._id">
                                                    {{ server.name }}
                                                </b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group label="Destino">
                                            <b-form-select class="w-100 py-1" style="border-color: #ccc; border-radius: 0.3em" v-model="objAux.importTo">
                                                <b-form-select-option :value="server._id" v-for="server in serversConfig" :key="server._id">
                                                    {{ server.name }}
                                                </b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </form>
                        </div>
                    </div>
                    <div class="btnsdiv pb-4 mt-3">
                        <b-button class="btns me-2" variant="success" @click="importGobot" v-if="objAux.importGobot">
                            <div class="d-flex align-items-center justify-content-center">
                                <b-spinner class="me-2" small v-if="importingGobot"/>
                                Importar
                            </div>
                        </b-button>
                        <b-button class="me-2" variant="success" @click="importServer" v-if="objAux.importServer">
                            <div class="d-flex align-items-center justify-content-center">
                                <b-spinner class="me-2" small v-if="importingServer"/>
                                Importar/Exportar
                            </div>
                        </b-button>
                        <b-button type="submit" class="btns btnconfirm">Salvar</b-button>
                        <b-button class="btns btncancel" @click="$bvModal.hide('modal-channelSave')">Cancelar</b-button>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-timeedit" ref="modal-timeedit" title="Editar Horário" size="sm">
            <form>
                <b-input-group class="mb-3">
                    <b-form-input
                        id="example-input"
                        type="text"
                        placeholder="Horário"
                        v-model="value"
                    ></b-form-input>
                    <b-input-group-append class="timepicker">
                        <b-form-timepicker
                        button-only
                        right
                        locale="pt-BR"
                        aria-controls="example-input"
                        label-no-time-selected=""
                        v-model="value"
                        ></b-form-timepicker>
                    </b-input-group-append>
                </b-input-group>
                <div class="btnsdiv">
                    <b-button type="submit" class="btns btnconfirm" style="width: 100px">Salvar</b-button>
                    <b-button class="btns btncancel" style="width: 100px" @click="$bvModal.hide('modal-timeedit')">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-channeldisable" ref="modal-channeldisable" title="Deletar Canal" size="sm" header-class="p-0 px-3" body-class="p-0">
            <form @submit.prevent="deleteChannel">
                <div class="border-top text-secondary smaller-text px-3 pt-3">Tem certeza de que deseja deletar este Canal?</div>
                <div class="btnsdiv p-3">
                    <b-button type="submit" class="btns btnconfirm" style="width: 100px">Deletar</b-button>
                    <b-button class="btns btncancel" style="width: 100px" @click="$bvModal.hide('modal-channeldisable')">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-channelDisconnect" ref="modal-channelDisconnect" title="Desconectar Canal" size="sm" header-class="p-0 px-3 border-0" body-class="p-0" hide-footer>
            <form @submit.prevent="disconnectChannel()">
                <div class="border-top text-secondary smaller-text px-3 pt-3">Tem certeza de que deseja desconectar este Canal?</div>
                <div class="btnsdiv p-3">
                    <b-button type="submit" class="btns btnconfirm">Desconectar</b-button>
                    <b-button class="btns btncancel" style="width: 100px" @click="$bvModal.hide('modal-channelDisconnect')">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-channelRecreate" ref="modal-channelRecreate" title="Recriar Contêiner" header-class="p-0 px-3 border-0" body-class="p-0" hide-footer>
            <div class="border-top text-secondary smaller-text px-3 pt-3">Tem certeza de que deseja recriar o contêiner deste Canal?</div>
            <div class="hstack gap-3 p-3">
                <b-button class="btns btnconfirm m-0 hstack justify-content-center gap-2" :disabled="loadingRecreate" @click="recreateContainer()">
                    <b-spinner small v-if="loadingRecreate" />
                    Recriar
                </b-button>
                <b-button class="btns btnconfirm m-0 hstack justify-content-center gap-2" :disabled="loadingRecreate" @click="recreateContainer(true)">
                    <b-spinner small v-if="loadingRecreate" />
                    Desconectar e Recriar
                </b-button>
                <b-button class="btns btncancel" @click="$bvModal.hide('modal-channelRecreate')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-channelRestart" ref="modal-channelRestart" title="Reiniciar Contêiner" size="sm" header-class="p-0 px-3 border-0" body-class="p-0" hide-footer>
            <div class="border-top text-secondary smaller-text px-3 pt-3">Tem certeza de que deseja reiniciar o contêiner deste Canal?</div>
            <div class="hstack gap-3 p-3">
                <!-- <a :href="`${apiBaseUrl}/restart/OO3o3IMq594KuHkrarMrWhTQTQ/${channelSelected._id}`" target="_blank" class="btn btnconfirm" v-if="channelSelected && apiBaseUrl">Reiniciar</a> -->
                <b-button class="btns btnconfirm m-0 hstack justify-content-center gap-2" :disabled="loadingRestart" @click="restartContainer">
                    <b-spinner small v-if="loadingRestart" />
                    Reiniciar
                </b-button>
                <b-button class="btns btncancel" @click="$bvModal.hide('modal-channelRestart')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-channelContainerConfig" ref="modal-channelContainerConfig" title="Configuração de Contêiner" size="sm" header-class="p-0 px-3" body-class="p-3" hide-footer>
            <div class="vstack gap-3" v-if="channelSelected">
                <div class="vstack gap-1" v-if="channelSelected.server !== channelSelected._id && serverName">
                    <b-button class="hstack justify-content-center gap-2" variant="primary" @click="createContainer(serverName, channelSelected._id, channelSelected.channelNumber)" :disabled="loadingCreate">
                        <b-spinner v-if="loadingCreate" />
                        Criar Contêiner
                    </b-button>
                    <div class="smaller-text text-center text-secondary">
                        Lembre-se de alterar o canal para a nova API nas configurações do canal
                    </div>
                </div>
                <b-button class="hstack justify-content-center gap-2 text-white" variant="warning" :disabled="loadingRestart" v-b-modal.modal-channelRestart v-if="channelSelected.server === channelSelected._id && serverName">
                    <b-spinner v-if="loadingRestart" />
                    Reiniciar Contêiner
                </b-button>
                <b-button class="hstack justify-content-center gap-2" variant="danger" :disabled="loadingRecreate" v-b-modal.modal-channelRecreate v-if="channelSelected.server === channelSelected._id && serverName">
                    <b-spinner v-if="loadingRecreate" />
                    Recriar Contêiner
                </b-button>
            </div>
        </b-modal>
        <b-modal id="modal-importContacts" ref="modal-importContacts" title="Importar Contatos" header-class="p-0 px-3" body-class="p-0" hide-footer>
            <div class="p-3 d-flex flex-column align-items-center">
                <div class="text-purple mb-2">Arquivo de Contatos</div>
                <b-form-file id="contactsSheetUpload" style="display:none" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @input="selectContactSheet"></b-form-file>
                <label for="contactsSheetUpload" class="mb-2">
                    <div class="bg-grey text-secondary border-0 small-text p-2 rounded darkenOnHover">Anexar Arquivo</div>
                </label>
                <div class="text-secondary smaller-text">Tamanho máximo de 10MB e </div>
                <div class="text-secondary smaller-text">limite de 5000 contatos por planilha.</div>
                <div class="rounded p-2 text-center bg-secondary text-white mt-2 w-100" v-if="importContactInfo?.msg">
                    <div >
                        {{ importContactInfo.msg.text }}
                    </div>
                    <div class="mt-2">
                        <div v-if="importContactInfo.success?.length">
                            <div class="d-flex align-items-center justify-content-center bg-success text-white darkenOnHover rounded" v-b-toggle.collapse-success role="button">
                                Sucesso
                            </div>
                            <b-collapse id="collapse-success">
                                <div class="bg-grey text-dark rounded mt-1">
                                    <div class="importInfoContact p-2 border-bottom border-white" v-for="item in importContactInfo.success" :key="item.id">
                                        {{ item.contact?.nome || item.contact?.Nome || 'Campo "nome" vazio' }} - {{ item.contact?.telefone || item.contact?.Telefone || 'Campo "telefone" vazio' }}
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                        <div v-if="importContactInfo.failed?.length">
                            <div class="d-flex align-items-center justify-content-center bg-danger text-white darkenOnHover rounded" v-b-toggle.collapse-failed role="button">
                                Falha
                            </div>
                            <b-collapse id="collapse-failed">
                                <div class="bg-grey text-dark rounded mt-1">
                                    <div class="importInfoContact p-2 border-bottom border-white" v-for="item in importContactInfo.failed" :key="item.id">
                                        {{ item.contact?.nome || item.contact?.Nome || item.contact?.name || 'Campo "nome" vazio' }} - {{ item.contact?.telefone || item.contact?.Telefone || item.contact?.number || 'Campo "telefone" vazio' }} - {{ item.message }}
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
            <form @submit.prevent="importContactsSheet">
                <div class="btnsdiv border-top p-3">
                    <b-button type="submit" class="btns btnconfirm" :disabled="!contactsSheetData">
                        <b-spinner class="me-2" small v-if="importingContactsSheet"/>
                        Importar
                    </b-button>
                    <b-button class="btns btncancel" style="width: 100px" @click="$bvModal.hide('modal-importContacts')">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
    </div>    
</template>

<script>
// import Map from './map.vue'
// import userService from '../services/userService.js'
import api from '../services/apiService.js'
import * as XLSX from 'xlsx';
import countriesOptionsJSON from '../utils/countriesOptions.json'
const AIDefaultPrompt = "Você é um assistente virtual prestativo e está respondendo perguntas sobre produtos.\nSe você não sabe a resposta, diga que não encontrou produtos relacionados e fale um pouco sobre os produtos que temos e o que o cliente pode encontar com nossa noja. Mantenha a resposta concisa.\nSeparado da resposta sempre envie os ids dos produtos em um array Exemplo: IDs dos produtos: [id,id]. Gere um titulo e uma descrição.\nNunca repita a resposta.\n\nPergunta: {input}\nContexto: {context}"

export default {
    props: [
        'socket', 'user'
    ],
    components: {
        // Map
    },
    mounted() {
        this.onCloseModal()
        this.$nextTick(function() {
            this.getConfigFile()
            this.loadChannels()
            this.getContactsCount()
            this.getOperatorsCount()
            this.getApiServers()
            this.setCountryOptions()
            this.getServersConfig()

            this.socket.on('status_import_service_gobot', (data) => {
                console.log('status_import_service_gobot data',data)
                if(data.channelId == this.objAux?._id) {
                    this.importingGobot = false
                    if(data.error) {
                        this.$emit('msg', {
                            text: data.error,
                            success: false
                        })
                    } else {
                        this.importGobotSuccess = data
                        this.$emit('msg', {
                            text: "Importação concluida com sucesso!",
                            success: true
                        })
                    }
                }
            })
        })
    },
    methods: {
        async getConfigFile() {
            const config = await api.getConfigFile()
            if (config.statusCode !== 404) {
                this.apiBaseUrl = config.apiBaseUrl
                this.serverName = config.serverName
            }
        },
        async selectChannel(item) {
            const respChannel = await api.getChannel(item._id)
            // console.log('resp channel',respChannel)
            const respConfig = await api.getChannelConfig(item._id)
            // console.log('resp config',respConfig)
            if(respChannel.statusCode != 200 || !respChannel.channel.channel._id) {
                item.password = ''
                this.objAux = Object.assign({}, item);
                this.channelSelected = item;
            } else {
                respChannel.channel.channel.password = ''
                respChannel.channel.channel.login = respChannel.channel.user?.login
                this.objAux = Object.assign({}, respChannel.channel.channel);
                this.channelSelected = respChannel.channel.channel;
            }
            if(respConfig.statusCode != 200 || !respConfig.channelConfig._id) {
                if(this.$refs['modal-channelSave'])
                    this.$refs['modal-channelSave'].hide() 
                return this.$emit('msg', {
                    text:'Ocorreu um erro!',
                    success: false,
                })
            } else {
                if (!respConfig.channelConfig.gupshup) respConfig.channelConfig.gupshup = {}
                this.objAux.channelConfig = Object.assign({}, respConfig.channelConfig)
                this.channelSelected.config = respConfig.channelConfig

                if (this.objAux.channelConfig?.modules.ai) {
                    const aiResp = await api.getAIConfigByChannelId(item._id)

                    if (aiResp.statusCode === 200) {
                        this.objAux.aiSystemPrompt = aiResp.data.systemPrompt
                        this.channelSelected.aiSystemPrompt = aiResp.data.systemPrompt

                        try {
                            const containerPrompt = await api.getAIPromptFromContainer(aiResp.data.servicePort)

                            if (containerPrompt.current_prompt) {
                                this.objAux.aiSystemPrompt = containerPrompt.current_prompt
                                this.channelSelected.aiSystemPrompt = containerPrompt.current_prompt
                            }
                        // eslint-disable-next-line no-empty
                        } catch {}
                    } else {
                        this.channelSelected.aiSystemPrompt = this.objAux.aiSystemPrompt = AIDefaultPrompt
                    }
                }
            }
            if(this.objAux.mainChannelId) {
                this.setMainChannelData(this.objAux.mainChannelId,true)
            }
            if(!this.objAux.channelConfig.modules) {
                this.objAux.channelConfig.modules = {}
            }
            if(!this.objAux.channelConfig.contractedPlan) {
                this.objAux.channelConfig.contractedPlan = {}
            }
            if(!this.objAux.channelConfig.modules.activeCommunication) {
                this.objAux.channelConfig.modules.activeCommunication = false
            }
            if(this.objAux.createdAt) {
                this.objAux.createdAt = this.$options.filters.date(this.objAux.createdAt,'YYYY-MM-DD')
            }
            this.getDepartments()
            this.getOperators()
            this.checkNumberAndDDI(this.objAux.channelNumber)
            // console.log('channel',this.objAux)
        },
        async saveChannel() {
            if(!this.objAux.name) {
                return this.$emit('msg', {
                    text:'Campo nome está vazio!',
                    success: false,
                })
            }
            if(!this.objAux.ddi) {
                return this.$emit('msg', {
                    text:'Campo País está vazio ou inválido!',
                    success: false,
                })
            }
            if(this.objAux.channelNumber)
                this.objAux.channelNumber = this.objAux.channelNumber.replace(/\D+/g, '')

            if(this.objAux.companyCnpj)
                this.objAux.companyCnpj = this.objAux.companyCnpj.replace(/\D+/g, '')

            if(this.objAux.zipcode)
                this.objAux.zipcode = this.objAux.zipcode.replace(/\D+/g, '')

            if(this.objAux.apiType == 'cloud')
                this.objAux.server = 16

            if(this.objAux.apiType == 'business' && !this.user.admin?.master && !this.objAux.server) {
                const s = this.servers.filter(el => ['business','api0'].includes(el.apiType))
                if(s.length)
                    this.objAux.server = s[0].code
            }

            this.objAux.role = 'channel'
            let isNew
            if(!this.objAux._id)
                isNew = true

            let resp
            let msg = ''
            if(this.objAux.channelConfig)
                this.objAux.channelConfig.enableOperators = true

            if(!this.objAux.blocked)
                this.objAux.blocked = false

            if(this.objAux.mainChannelId && this.objAux.primaryChannelApiType == 'cloud' && !this.objAux.channelConfig?.operationalChannelEnabled)
                this.objAux.channelConfig.operationalChannelEnabled = true

            if(isNew) {
                this.objAux.adminId = this.user.roleId
                // console.log('objAux',this.objAux)
                if(this.objAux.mainChannelId) {
                    resp = await api.createSecChannel(this.objAux)
                } else {
                    resp = await api.createUser(this.objAux)
                }
            } else {
                if((this.objAux.login != this.channelSelected.login) || this.objAux.password) {
                    // console.log('user',this.objAux)
                    const userEdit = await api.updateUser(this.objAux)
                    if(userEdit.statusCode == 409) {
                        return this.$emit('msg', {
                            text: `O email digitado já existe!`,
                            success: false
                        })
                    }
                }
                if(this.objAux.blocked != this.channelSelected.blocked) {
                    const respBlock = await api.blockChannel(this.objAux._id,this.channelSelected.blocked)
                    console.log('respBlock',respBlock)
                }
                resp = await api.updateChannel(this.objAux)
            }
            console.log('resp save',resp)
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                msg = {
                    text: `Canal ${isNew ? 'criado' : 'editado'} com sucesso`,
                    success: true
                }
                if(this.objAux.channelConfig) {
                    if(resp.user || resp.channel) {
                        this.objAux.channelConfig.channelId = resp?.user?._id || resp?.channel?._id
                    }
                    if(!this.objAux.channelConfig.channelId) {
                        console.log("!this.objAux.channelConfig.channelId")
                    }
                    await api.updateChannelConfig(this.objAux.channelConfig)
                }

                if ((this.objAux.channelConfig?.modules.ai && this.objAux.aiSystemPrompt) || (!this.objAux.channelConfig?.modules.ai && this.objAux.aiSystemPrompt)) {
                    if (!this.objAux.channelConfig?.modules.ai)
                        this.objAux.aiSystemPrompt = ''

                    await api.updateAIConfigByChannelId(this.objAux._id, {
                        systemPrompt: this.objAux.aiSystemPrompt
                    })
                }

                this.$bvModal.hide('modal-pickPrimaryChannel')
                this.$bvModal.hide('modal-pickChannelType')
                this.$bvModal.hide('modal-pickChannelServer')
                this.channelType = null
                this.$refs['modal-channelSave'].hide()
            } else if(resp.statusCode == 406) {
                msg = {
                    text: `O limite de canais foi atingido!`,
                    success: false
                }
            } else if(resp.statusCode == 409) {
                msg = {
                    text: `O email digitado já existe!`,
                    success: false
                }
            } else { 
                msg = {
                    text:'Ocorreu um erro, revise os dados e tente novamente!',
                    success: false
                }
            } 
            this.$emit('msg',msg)
            this.loadChannels()
        },
        async deleteChannel() {
            const resp = await api.deleteChannel(this.objAux._id)
            let msg
            if(resp.error || resp.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro!',
                    success: false
                }
            } else {
                msg = {
                    text: "Canal deletado com sucesso!",
                    success: true
                }
            }
            this.$refs['modal-channeldisable'].hide()
            this.$emit('msg',msg)
            this.loadChannels()
        },
        async disconnectChannel(fromContainerUtils = false) {
            if (!this.socket.active)
                return this.$emit('msg', {
                    text: "Socket desconectado. Recarregue a página!",
                    success: false
                })

            this.socket.emit('channel_disconnect', this.channelSelected._id)
            
            if (!fromContainerUtils) {
                this.$emit('msg', {
                    text: "Canal derrubado com sucesso!",
                    success: true
                })
                
                if (this.channelSelected.server === this.channelSelected._id)
                    this.recreateContainer(false, true)

                this.$refs['modal-channelDisconnect'].hide()
            }
        },
        async loadChannels(page = 1) {
            let resp
            if(this.filters?.server || this.filters?.search) {
                if(!this.filterEnabled) {
                    page = 1
                    this.currentPage = 1
                }
                resp = await api.getChannelsFilter(this.filters,page)
                this.filterEnabled = true
                this.$refs['modal-channelfilter'].hide()
            } else {
                resp = await api.getChannels(page)
            }
            console.log('resp',resp)
            if(!resp || resp.statusCode!=200 || !resp.channels.channels) {
                this.channels = []
                this.channels.total = 0
                return
            }
            resp.channels.channels.map(async el => {
                try{
                    const r = await api.getChannelConfig(el._id)
                    if(r.statusCode === 200)
                        this.$set(el,'channelConfig',r.channelConfig)
                } catch {
                    console.log()
                }
                return el
            })
            this.channels = resp.channels.channels
            // console.log(this.channels)
            this.channels.total = resp.channels.count
        },
        disableFilter() {
            this.filters = {}
            this.filterEnabled = false
            this.currentPage = 1
            this.loadChannels()
        },
        setChannelAvatar(e) {
            const url = URL.createObjectURL(e)
            this.objAux.avatar = url
        },
        async getChannel(channelId) {
            const resp = await api.getChannel(channelId)
            if(resp.statusCode!=200)
                return null
            else
                return resp.channel.channel
        },
        async getChannelConfig(channelId) {
            const resp = await api.getChannelConfig(channelId)
            if(resp.statusCode!=200)
                return null
            else
                return resp.channelConfig
        },
        async getApiServers() {
            const resp = await api.getApiServers()
            // console.log('servers',resp)
            if(resp.statusCode!=200)
                this.servers = []
            else {
                if(this.user.login == 'admin' || this.user.admin?.master)
                    this.servers = resp.servers.filter(el => el.code != 18)
                else
                    this.servers = resp.servers.filter(el => ![18, 15, 21, 22].includes(el.code))
            }
        },
        async getServersConfig() {
            const resp = await api.getServersConfig()
            // console.log('servers', resp)
            this.serversConfig = resp.servers.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            this.objAux.serverSocket = this.getServerSocket()
        },
        primaryOnly(channels) {
            return channels.filter(el => !el.mainChannelId && (!this.primaryChannelSearch || el.name.toLowerCase().includes(this.primaryChannelSearch.toLowerCase()) || el.channelNumber.includes(this.primaryChannelSearch)))
        },
        pickTypeNext() { // opens the next modal when creating a channel and a type is selected
            switch(this.channelType) {
                case 1:
                case '1': // primary channel
                    this.$bvModal.show('modal-channelSave') // opens the channel creation modal
                    break;
                case 2:
                case '2': // secondary channel
                    this.$bvModal.show('modal-pickPrimaryChannel') // opens the modal to pick the primary channel
                    break;
                default:
                    this.$emit('msg', {
                        text:'Nenhum tipo selecionado!',
                        success: false,
                    })
                    break;
            }
        },
        async setMainChannelData(mainChannelId,fromEdit=false) {
            if(mainChannelId) {
                const primaryChannel = await this.getChannel(mainChannelId)
                const primaryChannelConfig = await this.getChannelConfig(mainChannelId)
                if(primaryChannel && !primaryChannel.mainChannelId) {
                    if(!fromEdit)
                        this.objAux = (({ enableOperators, operationalChannelEnabled, validateContact, attendanceMessageThreshold, sendStart, sendFinish, channelConfig = { modules: {}, contractedPlan: {} } }) => ({ enableOperators, operationalChannelEnabled, validateContact, attendanceMessageThreshold, sendStart, sendFinish, channelConfig }))(primaryChannel); // copy selected properties from primary to secondary

                    this.objAux.primaryChannelApiType = primaryChannel.apiType
                    this.objAux.channelConfig.modules.simultaneousAttendanceAmongDifferentChannels = primaryChannelConfig?.modules?.simultaneousAttendanceAmongDifferentChannels
                    // if(primaryChannel.apiType == 'cloud' ) {
                    //     this.objAux.channelConfig.operationalChannelEnabled = true
                    // }

                    await this.$set(this.objAux, 'mainChannelName', primaryChannel.name)

                    if(!fromEdit) {
                        await this.$set(this.objAux, 'mainChannelId', mainChannelId)
                        this.$bvModal.show('modal-channelSave')
                    }

                } else if(!fromEdit) {
                    this.$emit('msg', {
                        text:'Ocorreu um erro, tente novamente mais tarde!',
                        success: false,
                    })
                }
            }
        },
        async openClientPanel(client) {
            // console.log(client)
            let clientId = client._id
            if(client.mainChannelId)
                clientId = client.mainChannelId
            const resp = await api.generateAuthToken(clientId)
            // console.log('resp token',resp)
            if(resp.statusCode == 200) {
                // let token = resp.token
                resp.token.user.roleId = resp.token.roleId
                resp.token.user.channelNumber = resp.token.channelNumber
                resp.token.user.urlStorage = resp.token.urlStorage
                resp.token.user.urlServer = resp.token.urlServer
                
                const storageToken = JSON.stringify(resp.token.token),
                    storageTokenTimestamp = JSON.stringify(Date.now()),
                    storageUser = JSON.stringify(resp.token.user)

                localStorage.setItem('user', storageUser)
                localStorage.setItem('token', storageToken)
                localStorage.setItem('tokenTimestamp', storageTokenTimestamp)


                // if(resp.token.urlServer && resp.token.urlServer != `${window.location.protocol}//${window.location.host}`) {
                //     const storages = {
                //         token: storageToken,
                //         tokenTimestamp: storageTokenTimestamp,
                //         user: storageUser
                //     }
                //     const encryptedText = this.$CryptoJS.AES.encrypt(JSON.stringify(storages), process.env.VUE_APP_CRYPTO_PASS).toString()

                //     if(window.location.host.includes('localhost'))
                //         window.open(`http://localhost:8080/#/login?stg=${encodeURIComponent(encryptedText)}`)
                //     else
                //         window.open(`${resp.token.urlServer}/#/login?stg=${encodeURIComponent(encryptedText)}`)
                // }

                const url = `${window.location.href.split("/")[0]}`
                window.open(url,'_blank');
            } else {
                this.$emit('msg', {
                    text:'Ocorreu um erro, tente novamente mais tarde!',
                    success: false,
                })
            }
        },
        async getContactsCount() {
            const resp = await api.getContactsCountFromAllChannels()
            // console.log('resp contactsCount',resp)
            if(resp.statusCode == 200)
                this.contacts.total = resp.totalContacts
        },
        async getOperatorsCount() {
            const resp = await api.getOperatorsCountFromAllChannels()
            // console.log('resp operatorsCount',resp)
            if(resp.statusCode == 200)
                this.operatorsCount = resp.totalOperators
        },
        async setAddressByCep(e) {
            e = e.replace(/\D+/g, '')
            if(e.length == 8) {
                this.$viaCep.buscarCep(e).then((obj) => {
                    if(!obj.erro) {
                        this.objAux.address = `${obj.logradouro}, ${obj.bairro}, ${obj.localidade}, ${obj.uf}`
                        if(obj.complemento)
                            this.objAux.complement = obj.complemento
                    }
                })
            }
        },
        pageChange(e) {
            if(e != this.currentPage) {
                this.currentPage = e
                this.loadChannels(e)
            }
        },
        sortChannels(sort) {
            if(!this.sortCount[sort])
                this.sortCount[sort] = 1
            else
                this.sortCount[sort]++
            switch (sort) {
                case 'name':
                    if(this.sortCount[sort] % 2) { // odd number
                        this.channels = this.channels.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) // sort asc
                    } else { // even number
                        this.channels = this.channels.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0)) // sort desc
                    }
                break
                case 'type':
                    if(this.sortCount[sort] % 2) { // odd number
                        this.channels = this.channels.sort((a,b) => (a.apiType > b.apiType) ? 1 : ((b.apiType > a.apiType) ? -1 : 0)) // sort asc
                    } else { // even number
                        this.channels = this.channels.sort((a,b) => (a.apiType < b.apiType) ? 1 : ((b.apiType < a.apiType) ? -1 : 0)) // sort desc
                    }
                break
                case 'lastSended':
                    if(this.sortCount[sort] % 2) { // odd number
                        this.channels = this.channels.sort((a,b) => (a.lastSendedMessage > b.lastSendedMessage) ? 1 : ((b.lastSendedMessage > a.lastSendedMessage) ? -1 : 0)) // sort asc
                    } else { // even number
                        this.channels = this.channels.sort((a,b) => (a.lastSendedMessage < b.lastSendedMessage) ? 1 : ((b.lastSendedMessage < a.lastSendedMessage) ? -1 : 0)) // sort desc
                    }
                break
                case 'lastReceived':
                    if(this.sortCount[sort] % 2) { // odd number
                        this.channels = this.channels.sort((a,b) => (a.lastReceivedMessage > b.lastReceivedMessage) ? 1 : ((b.lastReceivedMessage > a.lastReceivedMessage) ? -1 : 0)) // sort asc
                    } else { // even number
                        this.channels = this.channels.sort((a,b) => (a.lastReceivedMessage < b.lastReceivedMessage) ? 1 : ((b.lastReceivedMessage < a.lastReceivedMessage) ? -1 : 0)) // sort desc
                    }
                break
                case 'status':
                    if(this.sortCount[sort] % 2) { // odd number
                        this.channels = this.channels.sort((a,b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0)) // sort asc
                    } else { // even number
                        this.channels = this.channels.sort((a,b) => (a.status < b.status) ? 1 : ((b.status < a.status) ? -1 : 0)) // sort desc
                    }
                break
            }
        },
        copyToClipboard(message) {
            navigator.clipboard.writeText(message)
        },
        showMessage(text) {
            this.$emit('msg', {
                text,
                success: true,
                countdown: 2
            })
        },
        importGobot() {
            if(!this.importingGobot) {
                let msg
                if(!this.objAux.gobotClientId) {
                    msg = {
                        text: "Campo 'ClientId' vazio!",
                        success: false
                    }
                } else if(!this.objAux.gobotPassword) {
                    msg = {
                        text: "Campo 'Password' vazio!",
                        success: false
                    }
                }
                if(msg)
                    this.$emit('msg',msg)

                this.importingGobot = true
                this.socket.emit('import_gobot',{ channelId: this.objAux._id, clientId: this.objAux.gobotClientId, password: this.objAux.gobotPassword, pages: this.objAux.gobotPages })
            }
        },
        selectContactSheet(file) {
            // console.log('file',file)
            if(file) {
                if(file.size > 10000000) {
                    return this.$emit('msg', {
                        text: "O tamanho máximo permitido para envios é de 10 MB",
                        success: false
                    })
                } else {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        const arrayBuffer = fileReader.result;
                        const data = new Uint8Array(arrayBuffer);
                        const workbook = XLSX.read(data, { type: 'array', cellText: false, cellDates: true });
                        const firstSheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[firstSheetName];

                        Object.keys(worksheet).forEach(function(s) {
                            if(worksheet[s].t === 'n') {
                                worksheet[s].z = '0';
                                worksheet[s].t = 's';
                            }
                        });
    
                        let jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: 'dd/mm/yyyy', rawNumbers: true });
                        jsonData = jsonData.map((el)=>{
                            el = Object.fromEntries(
                                Object.entries(el).map(([key, value]) => 
                                    [key.trim(), value]
                                )
                            )
                            return el
                        })
                        
                        console.log('sheetData: ',jsonData);

                        if(jsonData.length > 5000) {
                            return this.$emit('msg', {
                                text: "O máximo de contatos por planilha (5000) foi excedido!",
                                success: false
                            })
                        }
    
                        this.contactsSheetData = jsonData;
                    };
                    fileReader.readAsArrayBuffer(file);
                }
            }
        },
        async importContactsSheet() {
            if(!this.importingContactsSheet) {
                this.importingContactsSheet = true
                const resp = await api.importContactsSheet({ data: this.contactsSheetData, channelId: this.channelSelected._id })
                this.importingContactsSheet = false
                let msg
                if(resp.statusCode == 200) {
                    msg = {
                        text: resp.message,
                        success: true
                    }
                    this.contactsSheetData = {}
                } else {
                    msg = {
                        text: resp.message || "Ocorreu um erro!",
                        success: false
                    }
                }
                this.importContactInfo = {
                    msg,
                    ...resp
                }
                this.$emit('msg',msg)
            }
        },
        updateObjAux() {
            this.$set(this.objAux, "blocked", !this.objAux.blocked)
            this.$set(this.objAux, "blocked", !this.objAux.blocked)
        },
        async getOperators() {
            const resp = await api.getOperators(this.channelSelected.mainChannelId || this.channelSelected._id, true)
            if(resp.statusCode!=200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }
            this.operators = resp.operators
            // console.log('operators',this.operators)
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.channelSelected.mainChannelId || this.channelSelected._id, true)
            if(resp.statusCode!=200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }
            this.departments = resp.departments
            // console.log('departments',this.departments)
        },
        refreshObjAux() {
            this.$set(this.objAux,"name",this.objAux.name?.concat(' '))
            this.$set(this.objAux,"name",this.objAux.name?.trimEnd())
        },
        setCountryOptions() {
            const countryMap = countriesOptionsJSON.reduce((map, c) => map.set(c.isoCode, c), new Map())
            this.countryOptions = Array.from(countryMap.values()).map(c => ({
                ...c,
                label: this.getCountryName(c.code),
            }))
        },
        getCountryName(countryCode) { // returns the name of the country by the received country code
            if(countryCode) {
                const d = new Intl.DisplayNames(
                    ['pt-BR'], {type: 'region'}
                )
                return d.of(countryCode)
            }
        },
        checkNumberAndDDI(e) {
            if(e.length) {
                if(e.length > 2 && e.slice(0, 2) == 55) {
                    this.objAux.ddi = this.countryOptions.find(el => el.value == e.slice(0, 2))?.value
                } else if(e.length > 0 && e.length < 4) {
                    const find = this.countryOptions.find(el => el.value == e.slice(0, e.length))
                    if(find)
                        this.objAux.ddi = find.value
                }
            }

            if (typeof this.objAux.ddi !== 'string') {
                this.objAux.ddi = this.objAux.ddi?.value
            }
        },
        setSelectedOption (options, value) {
            if(value.value === undefined) {
                const option = options.find(x => x.value.toString() === value.label)
                return option.label
            } else {
                return value.label
            }
        },
        importServer() {
            let msg
            if(!this.objAux.importFrom) {
                msg = {
                    text: "Campo Origem vazio!",
                    success: false
                }
            } else if(!this.objAux.importTo) {
                msg = {
                    text: "Campo Destino vazio!",
                    success: false
                }
            } else {
                msg = {
                    text: "Importação iniciada!",
                    success: true
                }
                this.socket.emit("import_gotalk", { from: this.objAux.importFrom, to: this.objAux.importTo, channelId: this.objAux._id })
            }
            if(msg)
                this.$emit("msg",msg)
        },
        async restartContainer() {
            if (!this.loadingRestart) {
                this.loadingRestart = true
                
                const resp = await api.restartBusinessContainer(this.serverName, this.channelSelected._id)
    
                if (resp.message) {
                    this.$emit('msg', {
                        text: resp.message,
                        success: resp.message.includes('sucesso')
                    })
                } else {
                    this.$emit('msg', {
                        text: "Erro ao reiniciar contêiner!"
                    })
                }
                
                this.$bvModal.hide('modal-channelRestart')
                this.loadingRestart = false
            }
        },
        async createContainer() {
            if (!this.loadingCreate) {
                this.loadingCreate = true

                const resp = await api.createBusinessContainer(this.serverName, this.channelSelected._id, this.channelSelected.channelNumber)

                if (resp.message) {
                    this.$emit('msg', {
                        text: resp.message,
                        success: resp.message.includes('sucesso')
                    })
                    this.getApiServers()
                } else {
                    this.$emit('msg', {
                        text: "Erro ao criar contêiner!"
                    })
                }

                this.$bvModal.hide('modal-channelContainerConfig')
                this.loadingCreate = false
            }
        },
        async recreateContainer(disconnect = false, silent = false) {
            if (!this.loadingRecreate) {
                this.loadingRecreate = true
                
                if (disconnect)
                    this.disconnectChannel(true)

                const resp = await api.recreateBusinessContainer(this.serverName, this.channelSelected._id, this.channelSelected.channelNumber)
                
                console.log({resp})
                
                if (!silent) {
                    if (resp.message) {
                        this.$emit('msg', {
                            text: resp.message,
                            success: resp.message.includes('sucesso')
                        })
                    } else {
                        this.$emit('msg', {
                            text: "Erro ao recriar contêiner!"
                        })
                    }
                    
                    this.$bvModal.hide('modal-channelRecreate')
                }
                this.loadingRecreate = false
            }
        },
        onChangeModuleAI(e) {
            this.refreshObjAux()

            if (e && !this.objAux.aiSystemPrompt) {
                this.objAux.aiSystemPrompt = AIDefaultPrompt
            }
        },
        getServerSocket() {
          return this.serversConfig?.find(server => server.name.toLowerCase() === this.serverName)?._id
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                if (modalId !== 'modal-channelRestart' && modalId !== 'modal-channelRecreate')
                    this.channelSelected = null

                this.objAux = { channelConfig: { modules: {}, contractedPlan: {} }, serverSocket: this.getServerSocket() };
                this.importGobotSuccess = null
                this.contactsSheetData = null
                this.importContactInfo = null
                this.operators = []
                this.departments = []
            })
        },
    },
    data() {
        return {
            objAux: { channelConfig: { modules:{}, contractedPlan:{} } },
            baseUrl: `${process.env.VUE_APP_BASEURL}:5962`,
            form: { type:'channel' },
            filters: {},
            filterEnabled: false,
            sortCount: [],
            value: '',
            channelsPerPage: 10,
            currentPage: 1,
            channelSelected: null,
            channelType: null,
            primaryChannelSearch: '',
            channels: [],
            departments: [],
            operators: [],
            servers: [],
            serversConfig: [],
            operatorsCount: {
                online: 0,
                offline: 0
            },
            contacts: {
                total: 0
            },
            importGobotSuccess: null,
            importingGobot: false,
            importingServer: false,
            importingContactsSheet: false,
            contactsSheetData: null,
            importContactInfo: null,
            windowHost: window.location.host,
            countryOptions: [],
            apiBaseUrl: null,
            serverName: 'dev',
            loadingCreate: false,
            loadingRecreate: false,
            loadingRestart: false,
        }
    }
    
}
</script>

<style>
    .page-managechannel select,.page-managechannel input,.page-managechannel textarea{
        color: #555 !important;
        border-color: #eee !important;
        padding: 5px !important;
    }
    .input-group-text{
        border: none;
    }
    .page-managechannel .col-form-label{
        color: #888;
        font-weight: 500;
    }
    .page-managechannel .form-switch, .form-check{
        padding: 0;
    }
    .page-managechannel .form-switch input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }
    .page-managechannel .form-switch label {
        cursor: pointer;
        text-indent: -9999px;
        width: 50px;
        height: 22px;
        background: #ddd;
        display: block;
        border-radius: 100px;
        position: relative;
        margin-top: -20px;
        margin-right: 10px;
    }
    .page-managechannel .form-switch label:after {
        content: '';
        position: absolute;
        top: 2.2px;
        left: 2px;
        width: 18px;
        height: 18px;
        background: hsl(356deg 90% 62%);
        border-radius: 28px;
        transition: 0.3s;
    }
    .page-managechannel .form-switch input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        background-color: hsl(93deg 44% 65%);
    }
    .row:not(:first-child) .form-group{
        margin-bottom: 20px;
    }
    .contactsconfigcheckbox .custom-control-label{
        margin-left: 5px;
        color: #777;
    }
    .tooltip-sidebar>.tooltip-inner {
        background-color: #eee;
        color: #777;
        font-size: 1.1em;
        padding: 4px 20px;
        border-radius: 25px;
        margin-right: 15px;
        margin-bottom: 40px;
    }
    .tooltip-sidebar .arrow{
        /* background-color: #eee; */
        height: 30px;
        width: 30px;
    }
    .modal-content{
        border: none;
    }
    .close{
        border: none;
        background-color: transparent;
        font-size: 2em;
    }
    #modal-channelSave .close{
        color: #fff;
    }
    #modal-channelSave .modal-header{
        background-color: hsl(242deg 21% 44%);
        color: #fff;
        border: none;
        padding: 0 2em;
    }
    #modal-channelSave .modal-header .modal-title{
        color: #fff;
    }
    #modal-channelSave .modal-body{
        padding: 0;
    }
    #modal-channelSave .modal-footer, #modal-timeedit .modal-footer, #modal-channeldisable .modal-footer, #modal-channelcreate .modal-footer{
        display: none;
    }
    .profilestar{
        margin-right: 5px;
    }
    .b-form-timepicker label{
        display: none;
    }
    .b-form-timepicker button{
        background-color: hsl(230deg 19% 94%);
        border-color: hsl(230deg 19% 94%);
        color: #555;
    }
    #modal-timeedit .modal-header, #modal-timeedit .close, #modal-channeldisable .modal-header, #modal-channeldisable .close, #modal-channelcreate .modal-header, #modal-channelcreate .close{
        color: #555;
    }
    #modal-channeldisable .modal-header{
        border: none;
        padding-bottom: 0;
    }
    #modal-channeldisable .modal-body{
        padding-top: 0;
    }
    .inputavatar>input{
        display: none;
    }
    .inputavatar>label:hover .modal-editImgSpan{
        display: inline;
    }
    .countrySelect .vs__dropdown-toggle{
        border-color: rgb(204, 204, 204);
        border-radius: 0.3em;
        padding: 0.25em 0px;
    }
</style>

<style scoped>
    h5{
        margin: 0;
    }
    .cardsrow{
        display: flex;
        justify-content: space-between;
    }
    .card{
        border: none;
        width: 100%;
    }
    .card:not(:first-child) {
        margin-top: 20px;
    }
    .cardchannelconf .card-header{
        background-color: hsl(242deg 21% 44%);
        color: #fff;
    }
    .card-header{
        color: #555;
    }
    .card-footer{
        padding: 1rem 1rem;
    }
    .card-header, .card-footer{
        background-color: #fff;
        border-color: #eee;
    }
    .channelcardshboxes{
        display: flex;
        align-items: center;
    }
    .channelcardshboxes select{
        transform: scale(0.75);
        background-color: hsl(339deg 79% 68%);
        color: #fff !important; 
        max-width: 25px;
        border-radius: 3px;
        border: 1px solid hsl(339deg 79% 68%) !important;
        padding: 0 10px !important;
    }
    .channelcardshboxes label{
        display: flex;
        align-self: center;
    }
    .channelcardshboxes select option{
        background-color: #fff;
        color: #888;
    }
    .channelcardheader{
        border-bottom: 1px solid #eee;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
     .channelcardtitle{
        color: #333;
        display: flex;
        align-self: center;
    }
    .channelcardheaderright{
        display: flex;
        align-items: center;
    }
    .channelcardtexttotal{
        color: #888;
    }
    .channelcardnumbertotal{
        margin: 0 20px;
        background-color: hsl(232deg 37% 62%);
        color: #fff;
        display:inline-block;
        border-radius:50%;
        font-size:20px;
        font-weight: 500;
    }
    .channelcardnumbertotal:before,
    .channelcardnumbertotal:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;

        padding-top:50%;
        padding-bottom:50%;
    }
    .channelcardnumbertotal:before {
        padding-left:8px;
    }
    .channelcardnumbertotal:after {
        padding-right:8px;
    }
    .channelcardshtitle{
        float: left;
        font-size: 0.9em;
        font-weight: 700;
        color: #777;
    }
    .channelconfheadertop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .channelcardbodyrow{
        padding: 10px 0px;
        border-bottom: 1px solid #eee;
    }
    .channelcardcol{
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-all;
    }
    .channelcardnamecb{
        margin-right: 10px;
    }
    .channelcardname{
        color: hsl(232deg 37% 62%);
        font-weight: 500;
    }
    .channelcardcontacts{
        color: hsl(232deg 39% 65%);
    }
    .channelcardoptionsbtn{
        padding: 6px;
        border: none;
        border-radius: 2px;
    }
    .channelcardoptionsbtn:last-child{
        margin-right: 0px;
    }
    .channelavatar{
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    .threedotsicon{
        color: hsl(242deg 21% 44%);
        background-color: #fff;
        font-size: 3em;
        padding: 10px;
        cursor: pointer;
    }
    .channelconfheadercontent{
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        padding-bottom: 25px;
    }
    .channelicon{
        height: 150px;
        width: 150px;
        border: 10px solid #fff;
    }
    .channeltitle{
        margin-top: 20px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 1.1em;
    }
    .channelstatus{
        margin: 0;
        color: hsl(244deg 69% 84%);
    }
    .channelconfcontent{
        padding: 16px !important;
    }
    .channelphone{
        font-weight: 500;
        font-size: 1.25em;
        color: hsl(246deg 8% 49%);
        margin: 0;
    }
    .cardchannelicon{
        color: hsl(246deg 8% 49%);
        font-size: 2em;
    }
    .channelphonediv{
        border-top: 0.5px dashed hsl(246deg 8% 85%);
        border-bottom: 0.5px dashed hsl(246deg 8% 85%);
        padding: 20px 0;
    }
    .channellastcomdiv{
        border-bottom: 0.5px dashed hsl(246deg 8% 85%);
        padding: 20px 0;
    }
    .channellastcomcol{
        align-self: center;
    }
    .channellastcomtext{
        font-weight: 500;
        color: hsl(246deg 8% 49%);
        line-height: 1.2em;
    }
    .channellastcomdate{
        color: hsl(246deg 8% 49%);
        line-height: 1.2em;
    }
    .inputweekhour .input-group{
        width: 100%;
    }
    .input-group-text{
        color: #888;
    }
    .page-managechannel .btnsdiv{
        padding-top: 25px;
    }
    .btns{
        font-weight: 500;
    }
    .btnconfirm{
        background-color: hsl(143deg 39% 59%);
        border-color: hsl(143deg 39% 59%);
        color: #fff;
        margin-right: 10px;
    }
    .btncancel{
        background-color: #fff;
        color: #777;
        border-color: #eee;
    }
    .cardcreatechannel{
        background-color: hsl(87deg 47% 59%);
        color: #fff;
        display: flex;
        text-align: center;
        padding: 30px 20px;
    }
    .cardcreatechanneltitle{
        font-size: 1.5em;
    }
    .cardcreatechannelbtn{
        background-color: #fff;
        border-color: #fff;
        color: hsl(87deg 47% 59%);
        margin-top: 20px;
    }
    .cardcreatechannelbtn:hover{
        background-color: hsl(87deg 47% 59%);
        color: #fff;    
    }
    .equal {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
    }
    .sidebar{
        display: flex;
        flex-direction: column;
        position: fixed;
        background-color: #eee;
        margin-top: 6vh;
        padding: 10px;
        box-shadow: 2px 2px 3px #ccc;
        border-radius: 5px;
        right: 5px;
        z-index: 5;
    }
    .sidebaricon{
        font-size: 1.2em;
        color: #aaa;
        margin: 8px 0;
        cursor: pointer;
    }
    .sidebaricon:hover{
        color: #555;
    }
    .threedotsdrop{
        position: relative;
        display: none;
    }
    .threedotsdroptriangle{
        height: 15px;
        width: 15px;
        position: absolute;
        background-color: #fff;
        top: -8px;
        transform: rotate(45deg);
        display: none;
    }
    .threedotsdropcontent{
        position: absolute;
        background-color: #fff;
        color: #555;
        padding: 15px 0;
        text-align: left;
        border-radius: 5px;
        top: -30px;
        left: -133px;
        cursor:default;
        font-size: 0.8em;
        z-index: 1;
    }
    .threedotsdropbtns{
        padding: 5px 20px;
        padding-right: 60px;
        white-space: nowrap;
        cursor: pointer;
        font-size: 1.15em;
    }
    .threedotsdropbtns:hover{
        background-color: #eee;
    }
    .threedots:hover .threedotsdrop{
        display: block;
    }
    .modal-channelSaveheader{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: hsl(242deg 21% 44%);
        padding-bottom: 2em;
    }
    .modal-channeliconspan{
        cursor: pointer;
        width: fit-content;
    }
    .modal-editImgSpan{
        display: none;
    }
    .modal-channelicon{
        height: 100px;
        width: 100px;
        border: 8px solid #fff;
    }
    .modal-channeliconshadow{
        background-color: black;
        position: absolute;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        top: 0;
        opacity: 0.75;
    }
    .modal-channelshadowtext{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute; 
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50px;
        color: #fff;
        font-weight: 500;
        font-size: 0.9em;
    }
    .modal-channelshadowicon{
        font-size: 1.3em;
    }
    .inputdiv{
        margin-bottom: 10px;
    }
    .rating{
        background-color: hsl(242deg 21% 44%);
        width: fit-content;
    }
    label,
    input[type="checkbox"] + span,
    input[type="checkbox"] + span::before
    {
        display: inline-block;
        vertical-align: middle;
    }
    label *
    {
        cursor: pointer;
    }
    input[type="checkbox"]
    {
        opacity: 0;
        position: absolute;
    }
    input[type="checkbox"] + span
    {
        color: #888;
        font-size: 0.9em;
        display: inline-flex;
        align-items: center;
    }
    label:hover span::before
    {
        -moz-box-shadow: 0 0 2px #ccc;
        -webkit-box-shadow: 0 0 2px #ccc;
        box-shadow: 0 0 2px #ccc;
    }
    input[type="checkbox"] + span::before
    {
        content: "";
        width: 18px;
        height: 18px;
        margin: 0 4px 0 0;
        border: solid 1px #ccc;
        line-height: 14px;
        text-align: center;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
    }
    input[type="checkbox"]:checked + span::before
    {
        color: #666;
        content: "\2713";
        font-size: 12px;
        font-weight: 900;
    }
    input[type="checkbox"]:disabled + span
    {
        cursor: default;
        -moz-opacity: .4;
        -webkit-opacity: .4;
        opacity: .4;
    }
    .row:last-child, .importInfoContact:last-child{
        border: none !important;
    }

    @media (max-width:425px) {
        .channelcardheader{
            padding: 1em;
            display: block;
        }
        .channelcardtitle,.channelcardheaderright{
            justify-content: center;
        }
        .channelcardnumbertotal{
            padding: 0.1em 0.25em;
        }
        .channelcardnumbertotal:before, .channelcardnumbertotal:after{
            content: none;
        }
        .channellastcomcol{
            margin-left: 1em;
        }
    }
</style>